/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';

import styles from './LogoReel.module.scss';
import clickoutScript from '../../utils/clickoutScript';

const RenderLogoReelSlide = ({ slide, small, clickout }) => {
  const primaryKey = (slide.category && slide.category.slug) || slide.title;

  const setImageWidth = () => {
    if (small) return '100';

    return '135';
  };

  const setImageHeight = () => {
    if (small) return '70';

    return '80';
  };

  return (
    <li key={`logoReel_${primaryKey}_${slide.order}`} className={styles.logo}>
      <a
        onClick={() => clickoutScript(slide.url, clickout, false)}
        href={clickoutScript(slide.url, clickout, true)}
        rel="noopener noreferrer"
      >
        <img
          alt={slide.title}
          loading="lazy"
          src={`https://res.cloudinary.com/crop/image/fetch/f_auto,q_70,c_pad,fl_lossy,w_${setImageWidth()},h_${setImageHeight()}/${
            slide.image
          }`}
          title={slide.title}
          width={setImageWidth()}
          height={setImageHeight()}
        />
      </a>
    </li>
  );
};

const LogoReel = ({ clickoutSlider, loading, error, clickout }) => {
  if (loading || error) {
    return null;
  }

  if (!clickoutSlider) {
    return null;
  }

  return (
    <ul className={classNames(styles.root, 'col-12-xs')} data-testid="logoReelTest">
      {clickoutSlider.slides.slice(0, 6).map((slide, index) => (
        <RenderLogoReelSlide small={false} key={index} slide={slide} clickout={clickout} />
      ))}
    </ul>
  );
};

LogoReel.displayName = 'LogoReel';
export default LogoReel;
