import React from 'react';
import { useRecommendedProducts } from '../../utils/useRecommendedProducts';
import { FormattedMessage } from 'react-intl';
import { useRecentlyViewedProducts } from '../../utils/useRecentlyViewedProducts';
import ProductSliderSection from '../ProductSliderSection';
import ProductWidgetSection from '../ProductWidgetSection';
import { SwitchableFeature } from '../../features/SwitchableFeature/SwitchableFeature';
import { CART } from '../../features/SwitchableFeature/SwitchableFeature.definitions';

const Title = () => (
  <>
    <span>
      <FormattedMessage default="Products" id="home.recommendedByRVP.title0" />
    </span>{' '}
    <FormattedMessage default="inpired by your recently viewed list" id="home.recommendedByRVP.title1" />
  </>
);

const WidgetTitle = () => (
  <>
    <FormattedMessage default="Popular" id="home.popular.title0" />{' '}
    <FormattedMessage default="Popular" id="home.popular.title1" />
  </>
);

const RecommendedByRVPSection = ({ classList }) => {
  const { recentlyViewedProducts } = useRecentlyViewedProducts();

  const rvpToIdList = recentlyViewedProducts?.map(({ id }) => id);
  const { recommendedProducts } = useRecommendedProducts(rvpToIdList);

  if (!recommendedProducts?.length) {
    return null;
  }

  return (
    <>
      <SwitchableFeature feature={CART}>
        <ProductWidgetSection
          products={recommendedProducts}
          title={<Title />}
          classList={{ sectionHeader: classList?.sectionHeader }}
          color="green"
          personalizedProduct={recommendedProducts}
          widgetTitle={<WidgetTitle />}
        />
      </SwitchableFeature>
      <SwitchableFeature dependencies={[[CART, false]]}>
        <ProductSliderSection
          products={recommendedProducts}
          title={<Title />}
          classList={{ sectionHeader: classList?.sectionHeader }}
        />
      </SwitchableFeature>
    </>
  );
};

export default RecommendedByRVPSection;
