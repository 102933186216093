import React from 'react';
import useWishlist from '../../utils/useWishlist';
import { useRecommendedProducts } from '../../utils/useRecommendedProducts';
import ProductSliderSection from '../ProductSliderSection';
import { FormattedMessage } from 'react-intl';
import { SwitchableFeature } from '../../features/SwitchableFeature/SwitchableFeature';
import { CART } from '../../features/SwitchableFeature/SwitchableFeature.definitions';
import ProductWidgetSection from '../ProductWidgetSection';

const Title = () => (
  <>
    <span>
      <FormattedMessage default="Products" id="home.recommendedByWishlist.title0" />
    </span>{' '}
    <FormattedMessage default="inpired by your wishlist" id="home.recommendedByWishlist.title1" />
  </>
);

const WidgetTitle = () => (
  <>
    <FormattedMessage default="Popular" id="home.popular.title0" />{' '}
    <FormattedMessage default="Popular" id="home.popular.title1" />
  </>
);

const RecommendedByWishlistSection = ({ classList }) => {
  const { wishlist } = useWishlist();
  const { recommendedProducts } = useRecommendedProducts(wishlist);

  if (!recommendedProducts?.length) {
    return null;
  }

  return (
    <>
      <SwitchableFeature feature={CART}>
        <ProductWidgetSection
          products={recommendedProducts}
          title={<Title />}
          classList={{ sectionHeader: classList?.sectionHeader }}
          color="blue"
          personalizedProduct={recommendedProducts}
          widgetTitle={<WidgetTitle />}
        />
      </SwitchableFeature>
      <SwitchableFeature dependencies={[[CART, false]]}>
        <ProductSliderSection
          products={recommendedProducts}
          title={<Title />}
          classList={{ sectionHeader: classList?.sectionHeader }}
        />
      </SwitchableFeature>
    </>
  );
};

export default RecommendedByWishlistSection;
