import React from 'react';
import loadable from '@loadable/component';
import styles from './ProductWidgetSection.module.scss';
import PageSectionHeader from '../PageSectionHeader';
import { useHistory } from 'react-router-dom';
import { VIEW_OPTIONS_TILES } from '../SearchResultsPage/SRP.definitions';
import getCDNImage from '../../utils/getCDNImage';
import classNames from 'classnames';
import ProductWidget from '../ProductWidget';

const Product = loadable(() => import('../Product/Product'));

const ProductWidgetSection = ({
  products = [],
  classList,
  title,
  color = 'orange',
  isReverse = false,
  personalizedProduct = [],
  widgetTitle,
}) => {
  const { push } = useHistory();
  const productWidget = personalizedProduct?.length ? personalizedProduct.slice(-4) : [];

  return (
    <section className={classList?.root || styles.root}>
      <PageSectionHeader
        classList={{ title: classList?.title, root: classList?.sectionHeader || styles.sectionHeader }}
      >
        {title}
      </PageSectionHeader>
      <div className={classNames('container')}>
        <div
          className={classNames({
            [styles.wrapper]: !isReverse && productWidget.length,
            [styles.reversedWrapper]: isReverse && productWidget.length,
          })}
        >
          <div
            className={classNames({
              [styles.sliderContainer]: productWidget.length,
              ['col-12-xs']: !productWidget.length,
            })}
          >
            <div className={styles.productList}>
              {products.map((product) => (
                <Product
                  adult={product?.adult}
                  key={`pssitem${product.id}`}
                  brand={product?.brand}
                  classList={{ root: styles.productCard }}
                  currency={product?.currency || process.env.REACT_APP_WEBSITE_CURRENCY}
                  shortDesc={product?.shortDesc}
                  EAN={product?.offers?.[0]?.EAN}
                  price={product.price}
                  originalPrice={product.price_original || product.price}
                  id={product.id}
                  image={getCDNImage(product?.thumbs?.[0])}
                  isSelected={false}
                  name={product.name}
                  onClick={() => push(product.pdp_path)}
                  shop={{ name: product.campaign }}
                  SKU={product.SKU}
                  showProductLink={false}
                  url={product.pdp_path}
                  view={VIEW_OPTIONS_TILES}
                  status={product.new}
                  shopId={product?.shop_id}
                  merchantPid={product?.merchant_product_id}
                />
              ))}
            </div>
          </div>
          {!!productWidget.length && (
            <div className={classNames(styles.productWrapper)}>
              <ProductWidget products={productWidget} color={color} widgetTitle={widgetTitle} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ProductWidgetSection;
